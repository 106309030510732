<template>
    <div style="max-width: 300px;margin: 0 auto;">
        <el-form :model="ruleForm" :rules="rules" ref="formCheck" label-width="80px" class="login-form">
            <el-form-item prop="name" label="权限名称">
                <el-input placeholder="请输入权限名称" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <div>
                <el-form-item v-if="ruleForm.new_priv_id" prop="new_priv_id" label="权限代码">
                    <el-input placeholder="请输入权限代码" v-model="ruleForm.new_priv_id"></el-input>
                </el-form-item>
                <el-form-item v-else prop="priv_id" label="权限代码">
                    <el-input placeholder="请输入权限代码" v-model="ruleForm.priv_id"></el-input>
                </el-form-item>
            </div>
            <el-form-item v-if="popTitle == '修改权限代码'" prop="cat_id" label="权限分类">
                <el-select style="width:100%;" v-model="ruleForm.cat_id" placeholder="请选择权限类型">
                    <el-option v-for="item in selectList" :key="item.id"
                    :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="priv_type" label="权限类型">
                <el-select style="width:100%;" v-model="ruleForm.priv_type" placeholder="请选择权限类型">
                    <el-option v-for="item in options" :key="item.id"
                    :label="item.value" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="desc" label="权限说明">
                <el-input type="textarea" :rows="3" placeholder="请输入权限说明" v-model="ruleForm.desc"></el-input>
            </el-form-item>
            <div style="padding-top:20px;">
                <el-button type="primary" @click="submitForm()" style="width: 100%;">提交</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
    import { getPrivCats,addPriv,setPriv } from '@/service/purview';
    export default {
        props: ['form','popTitle'],
        data() {
            return {
                rules: {
                    name: [
                        { required: true, message: '请输入权限名称', trigger: 'blur' }
                    ],
                    new_priv_id: [
                        { required: true, message: '请输入权限代码', trigger: 'blur' }
                    ],
                    priv_id: [
                        { required: true, message: '请输入权限代码', trigger: 'blur' }
                    ],
                    priv_type: [
                        { required: true, message: '请输入权限类型', trigger: 'blur' }
                    ],
                    desc: [
                        { required: true, message: '请输入权限说明', trigger: 'blur' }
                    ],
                },
                ruleForm:{
                    cat_id:"",
                    name:"",
                    new_priv_id:"",
                    priv_id:"",
                    priv_type:"",
                    desc:"",
                },
                options: [
                    { id:0, value: "管理员可分配" },
                    { id:1, value: "超级管理员独有" },
                    { id:2, value: "成员共有" },
                ],
                selectList:[],
            }
        },
        watch:{
            form:{
                handler() {
                    this.ruleForm = this.form;
                },
                deep: true
            }
        },
        created() {
            this.ruleForm = this.form;
            this.refreshItems();
        },
        mounted() {},
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getPrivCats().then(rst => {
                    if (rst && rst.length>0) {
                        this.selectList = rst;
                    }
                    console.log(this.selectList);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
                
            },
            submit() {
                if (this.popTitle == "新增权限代码") {
                    addPriv(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("添加成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.popTitle == "修改权限代码") {
                    setPriv(this.ruleForm).then(rst => {
                        this.$emit("refreshItems");
                        this.$emit("hide");
                        this.$message.success("修改成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            }
        }
    }
</script>
<style scoped>
    
</style>