<template>
    <div>
        <div class="page-header">权限代码列表</div>
        <div style="margin:20px;text-align:right;">
            <el-button @click="addParent()" size="small" type="primary">添加权限分类</el-button>
        </div>
        <div class="block" style="padding:20px;">
            <div class="list" v-for="(item,idx) in list" :key="idx">
                <div class="list-header flex">
                    <div class="flex-1">{{item.name}}</div>
                    <el-button type="text" size="small" @click="addSon(item)">
                        <span class="icon iconfont">+</span>&nbsp;添加权限代码
                    </el-button>
                    <el-button type="text" size="small" @click="editParent(item)">
                        <span class="icon iconfont icon-bianji1"></span>&nbsp;修改
                    </el-button>
                    <el-button type="text" size="small" @click="delParent(item)">
                        <span class="icon iconfont icon-shanchu"></span>&nbsp;删除
                    </el-button>
                </div>
                <div class="flex" style="line-height:40px;padding-left: 40px;padding-right: 20px;" v-for="(item1,idx1) in item.privs" :key="idx1">
                    <div class="item-header" style="min-width:200px;max-width:300px;">{{item1.name}}</div>
                    <div class="item-header flex-1">{{item1.id}}</div>
                    <div>
                        <el-button type="text" size="small" @click="editSon(item,item1)">
                            <span class="icon iconfont icon-bianji1"></span>&nbsp;修改
                        </el-button>
                        <el-button type="text" size="small" @click="delSon(item1)">
                            <span class="icon iconfont icon-shanchu"></span>&nbsp;删除
                        </el-button>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditClass v-if="popTitle=='新增权限代码分类' || popTitle=='修改权限代码分类'" :form="ruleForm" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></EditClass>
            <EditQx v-if="popTitle=='新增权限代码' || popTitle=='修改权限代码'" :form="ruleForm1" :popTitle="popTitle" @refreshItems="refreshItems" @hide="popVisible=false"></EditQx>
        </el-dialog>
    </div>
</template>
<script>
    import { getPrivs,delPrivCat,delPriv } from '@/service/purview';
    import EditClass from './EditClass.vue';
    import EditQx from './EditQx.vue';
    export default {
        components: {
            EditClass,EditQx
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                rules: {
                    name: [
                        { required: true, message: '请输权限分类名称', trigger: 'blur' }
                    ],
                    desc: [
                        { required: true, message: '请输权限分类说明', trigger: 'blur' }
                    ]
                },
                list:[],
                ruleForm:{
                    name:"",
                    desc:"",
                },
                ruleForm1:{
                    name:"",
                    priv_id:"",
                    cat_id:"",
                    priv_type:"",
                    desc:"",
                },
            }
        },
        mounted() {

        },
        created() {
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems');
                getPrivs().then(rst => {
                    if (rst && rst.length>0) {
                        this.list = rst;
                    }
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            addParent() {
                this.popTitle = "新增权限代码分类";
                this.popVisible = true;
                this.ruleForm.name = "";
                this.ruleForm.cat_id = "";
            },
            editParent(item) {
                this.popTitle = "修改权限代码分类";
                this.popVisible = true;
                this.ruleForm.name = item.name;
                this.ruleForm.cat_id = item.id;
                this.ruleForm.desc = item.desc;
            },
            delParent(item) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    delPrivCat({cat_id:item.id}).then(rst => {
                        this.refreshItems();
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            },
            addSon(item) {
                this.popTitle = "新增权限代码";
                this.popVisible = true;
                this.ruleForm1 = {
                    cat_id:item.id,
                    name:"",priv_id:"",priv_type:"",desc:"",
                };
            },
            editSon(item,item1) {
                this.popTitle = "修改权限代码";
                this.popVisible = true;
                // Object.assign(this.ruleForm1,item1);
                this.ruleForm1 = {
                    cat_id:item.id,
                    new_priv_id:item1.id,
                    name:item1.name,priv_id:item1.id,priv_type:item1.priv_type,desc:item1.desc,
                };
            },
            delSon(item1) {
                this.$confirm(`确认要删除吗?`, '温馨提示', {
                    confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'
                }).then(() => {
                    delPriv({priv_id:item1.id}).then(rst => {
                        this.refreshItems();
                        this.$message.success('删除成功!');
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                });
            },
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .list {
        margin-bottom: 20px;
        border:1px solid #ebeef5;
    }
    .list-header {
        font-size: 16px;
        font-weight: 500;
        line-height: 40px;
        border-bottom: 1px solid #c0c4cc;
        padding:0 20px;
    }
    .item-header {
        font-size: 14px;
        line-height: 35px;
    }
    .icon {
        font-size: 12px;
    }
</style>